import { useEffect } from 'react';

const HeadMetadata = () => {
  useEffect(() => {
    // Update page title
    document.title = 'Twenty Three Zero';

    // Update meta description
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute('content', 'Twenty Three Zero - Align your daily actions with long-term goals through structured time-based check-ins and reviews.');
    }

    // Update theme color
    const metaTheme = document.querySelector('meta[name="theme-color"]');
    if (metaTheme) {
      metaTheme.setAttribute('content', '#10B981'); // Using a green color to match your theme
    }
  }, []);

  return null;
};

export default HeadMetadata;
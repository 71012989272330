import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../lib/api';
import { User } from '../types';

interface AuthContextType {
  user: User | null;
  isAuthenticated: boolean;
  isLoading: boolean;
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  checkAuth: () => Promise<void>;
  refreshToken: () => Promise<string | null>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [initialCheckDone] = useState(false);
  const navigate = useNavigate();

  console.log('AuthProvider State:', { user, isLoading });

  const refreshToken = async (): Promise<string | null> => {
    try {
      const response = await api.post('/auth/refresh', {}, { withCredentials: true });
      const { access_token } = response.data;
      localStorage.setItem('token', access_token);
      return access_token;
    } catch (error) {
      localStorage.removeItem('token');
      setUser(null);
      return null;
    }
  };

  const checkAuth = async () => {
    console.log('Checking auth...');
    try {
      const response = await api.get('/auth/me');
      console.log('Auth check success:', response.data);
      setUser(response.data);
    } catch (error) {
      console.log('Auth check failed:', error);
      setUser(null);
      localStorage.removeItem('token');
    } finally {
      setIsLoading(false);
    }
  };

  const login = async (email: string, password: string) => {
    try {
      const formData = new URLSearchParams();
      formData.append('username', email);
      formData.append('password', password);
      formData.append('grant_type', 'password');

      const response = await api.post('/auth/web/login', formData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });

      const { access_token } = response.data;
      localStorage.setItem('token', access_token);

      await checkAuth();
      navigate('/home');
    } catch (error: any) {
      // Propagate the original error response
      if (error.response) {
        throw error.response;
      }
      throw new Error('Login failed');
    }
  };

  const logout = async () => {
    try {
      await api.post('/auth/logout');
    } catch (error) {
      console.error('Logout error:', error);
    } finally {
      localStorage.removeItem('token');
      setUser(null);
      navigate('/login');
    }
  };

  /// Check authentication status only when token changes
  useEffect(() => {
    const token = localStorage.getItem('token');
    console.log('Token changed:', token ? 'exists' : 'none');
    checkAuth();
  }, []);

  const value = {
    user,
    isAuthenticated: !!user,
    isLoading: isLoading && !initialCheckDone,
    login,
    logout,
    checkAuth,
    refreshToken
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}
// src/App.tsx
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Suspense, lazy } from 'react';
import { AuthProvider } from './context/AuthContext';
import { ProtectedRoute } from './components/auth/ProtectedRoute';
import HeadMetadata from './components/HeadMetadata';

// Lazy load routes
const Layout = lazy(() => import('./components/layout/Layout'));
const Home = lazy(() => import('./pages/Home'));
const DailyPage = lazy(() => import('./components/time/DailyPage'));
const Weekly = lazy(() => import('./pages/Weekly'));
const Monthly = lazy(() => import('./pages/Monthly'));
const Yearly = lazy(() => import('./pages/Yearly'));
const Values = lazy(() => import('./pages/Values'));
const Profile = lazy(() => import('./pages/Profile'));
const Login = lazy(() => import('./pages/auth/Login'));
const Register = lazy(() => import('./pages/auth/Register'));
const ForgotPassword = lazy(() => import('./pages/auth/ForgotPassword'));
const ResetPassword = lazy(() => import('./pages/auth/ResetPassword'));
const VerifyEmail = lazy(() => import('./pages/auth/VerifyEmail'));

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <HeadMetadata />
        <AuthProvider>
          <Suspense
            fallback={
              <div className="flex items-center justify-center min-h-screen">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-green-500" />
              </div>
            }
          >
            <Routes>
              {/* Public Routes */}
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/verify-email" element={<VerifyEmail />} />

              {/* Protected Routes */}
              <Route
                element={
                  <ProtectedRoute>
                    <Layout />
                  </ProtectedRoute>
                }
              >
                <Route path="/" element={<Navigate to="/home" replace />} />
                <Route path="/home" element={<Home />} />

                {/* Time Period Routes */}
                <Route path="/daily" element={<DailyPage />} />
                <Route path="/daily/:date" element={<DailyPage />} />
                <Route path="/weekly" element={<Weekly />} />
                <Route path="/weekly/:year/:week" element={<Weekly />} />
                <Route path="/monthly" element={<Monthly />} />
                <Route path="/monthly/:year/:month" element={<Monthly />} />
                <Route path="/yearly" element={<Yearly />} />
                <Route path="/yearly/:year" element={<Yearly />} />

                <Route path="/values" element={<Values />} />
                <Route path="/profile" element={<Profile />} />
              </Route>
            </Routes>
          </Suspense>
        </AuthProvider>
      </Router>
    </QueryClientProvider>
  );
}

export default App;